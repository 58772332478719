import React from 'react'
import Hero from './Accueil/Hero'
import Section1 from './Accueil/Section1'
import Section2 from './Accueil/Section2'

export default function render() {
  return (
  <div>
    <Hero/>
    <Section1/>
    <Section2/>
  </div>
  )
}
